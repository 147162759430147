.footer {
  // cursor: default;
  position: absolute;
  inset-block-end: 0;
  inset-inline-start: 0;
  inline-size: 100vi;
  block-size: 50px;
  background: $air-force-blue;

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    block-size: 100%;
    gap: 2rem;
  }
}