* {
  scroll-behavior: smooth;

  &::selection {
    background-color: $honeydew;
    color: $air-force-blue;
  }
}

body {
  position: relative;
  background: $eerie-black;
  cursor: none;
}

a,
label,
input,
textarea {
  cursor: inherit;
}

#root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about,
.portfolio,
.contact {
  max-inline-size: $content-inline-size;
}

.page-wrapper {
  block-size: 100vb;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.fa-react {
  color: $react-color;
}

.fa-css3-alt {
  color: $css-color;
}

.fa-sass {
  color: $sass-color;
}

.fa-js {
  color: $javascript-color;
}