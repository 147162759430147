.contact-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2em;

  form {
    inline-size: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    gap: 20px;

    textarea,
    input {
      padding: 9px;
      border: 1px solid $air-force-blue;
      background: rgba(255, 255, 255, 0.08);

      &:hover {
        border-color: $honeydew;
      }

      &:focus {
        border-color: $honeydew;
        box-shadow: $box-shadow;
        outline: none;
      }
    }

    input {
      inline-size: 100%;
      border-radius: 32px;
    }

    textarea {
      inline-size: 360px;
      block-size: 130px;
      resize: none;
      border-radius: 16px;
    }

    .message--submit {
      display: flex;
      align-items: center;
      gap: 2.2em;

      .send--button {
        display: flex;
        justify-content: center;
        align-items: center;
        inline-size: 88px;
        block-size: 88px;
        border: none;
        border-radius: 50%;
        background: $air-force-blue;
        transform: scale(1);
        transition: all .3s ease-in-out;

        &:hover {
          filter: drop-shadow(0 0 4px $apricot);
          transform: scale(1.05);

          img {
            transform: translate(-6px, 4px);
          }
        }

        img {
          transform: translate(-4px, 2px);
          transition: .3s ease-in-out;
        }
      }
    }

    .error-text {
      color: $mountbatten-pink;
      font-size: .8rem;
      font-weight: 700;
      font-style: italic;
    }
  }
}