button {
  background: transparent;
  transition: all .3s;
  cursor: inherit;
  z-index: 1;

  a {
    cursor: inherit;
  }

  &.button {
    position: relative;
    align-self: center;
    padding: .75rem 1.5rem;
    border: 1px solid $platinum;
    border-radius: 4px;

    &::before,
    &::after {
      content: '';
      position: absolute;
      inset-block-start: 0;
      inset-inline-start: 0;
      inline-size: 100%;
      block-size: 100%;
      background: rgba(255, 255, 255, 0.08);
      border-radius: 4px;
      opacity: 0;
      transition: all .3s;
      z-index: -1;
    }

    &::before {
      border: 1px solid $mountbatten-pink;
      transform: scaleY(2);
    }

    &::after {
      transform: scale(0);
      z-index: -1;
    }

    &:hover,
    &.checked {
      border: 1px solid $air-force-blue;
      color: $honeydew;

      &::before {
        opacity: 1;
        transform: scaleY(1);
      }

      &::after {
        opacity: 1;
        transform: scaleY(1);
      }
    }
  }

  &.glowing-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(16deg, $air-force-blue, $honeydew, $apricot);
    color: $eerie-black;
    border-radius: 50%;
    border: 1px solid $platinum;
    inline-size: 2.5rem;
    block-size: 2.5rem;
    padding-block-start: .2rem;
    transform: scale(1);
    transition: all .3s ease-in-out;
    // animation: box-shadow 2s infinite;
    box-shadow: .1rem .1rem .25rem rgba($color: #F2F4F3, $alpha: .56), inset 8px 12px 16px rgba($color: #FFF, $alpha: .25), 24px 24px 48px rgba($color: #000000, $alpha: .25), inset -12px -8px 16px rgba($color: #000000, $alpha: .25);

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      block-size: 100%;
      inline-size: 100%;
    }

    &:hover {
      transform: scale(1.1);
    }
  }
}