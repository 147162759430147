@import url('https://fonts.googleapis.com/css2?family=Overpass:ital,wght@0,100;0,200;0,400;0,700;1,100;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gruppo&display=swap');

$font-1: 'Overpass', sans-serif;
$font-2: 'Gruppo', cursive;

// Général
body {
  font-family: $font-1;
  color: $platinum;
  text-align: justify;
}

a {
  text-decoration: none;
  color: inherit;
}

.hovered-infos a {
  text-decoration: underline;

  &:hover {
    text-shadow: $text-shadow;
  }
}

button {
  color: $platinum;
  font-size: 1rem;
  letter-spacing: .1rem;

  &.glowing-button a {
    font-family: $font-1;
  }
}

.language-button,
.contact button {
  font-family: $font-2;
}

.navigation {
  button {
    font-family: $font-2;
  }

  a {
    font-weight: 500;

    &:hover {
      font-weight: bold;
    }
  }


  .resume__container {
    color: #FFF;
  }
}

.PortfolioProject {
  font-family: $font-1;

  h2 {
    font-family: $font-2;
  }

  .skills-list {
    font-weight: 100;
  }
}

.glitch {
  letter-spacing: .1rem;
  font-size: 1.5rem;
  font-weight: 100;
  text-shadow: 1px 1px 2px $honeydew;

  &::after {
    color: $mountbatten-pink;
  }

  &::before {
    color: $honeydew;
  }
}

.contact-form {
  h1 {
    text-align: center;
    font-size: 1.5em;
  }

  .form {
    span {
      font-size: .9em;
    }

    .name,
    .email,
    .message {
      &:focus-within {
        color: $honeydew;
      }
    }

    textarea,
    input {
      color: #FFF;
      font-family: inherit;

      &::placeholder {
        color: $platinum;
        font-family: inherit;
        font-weight: 100;
        font-size: .8rem;
      }

      &:focus {
        color: $honeydew;
      }
    }

    textarea {
      font-size: 1em;
    }
  }

  a:hover {
    color: $honeydew;
    text-decoration: underline;
  }

  @media screen and (max-block-size: 720px) {
    font-size: 12px;
  }
}

.error {
  text-transform: uppercase;
  font-size: 4.2em;
  letter-spacing: .1em;
}

.footer {
  color: #000;
}