.navigation {
  position: fixed;
  inset-block-start: 0;
  inset-inline-start: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  gap: 2rem;
  block-size: 100vb;
  inline-size: $nav-size;
  max-inline-size: 300px;
  padding-block-start: 1rem;
  padding-block-end: 4rem;
  background: transparent;
  cursor: none;
  overflow: hidden;

  img {
    inline-size: 100px;
    animation: grow-shadow 2s ease-in-out infinite;
    transition: 1s;
  }

  .navbar {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;

    button.button {
      inline-size: 8.5rem;
    }
  }
}