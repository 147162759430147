.glitch {
  // cursor: default;
  position: relative;
  inset-block-start: 0;
  inset-inline-start: 0;

  &::before,
  &::after {
    display: block;
    content: attr(data-glitch);
    position: absolute;
    inset-block-start: 0;
    inset-inline-start: 0;
    block-size: 100%;
    inline-size: 100%;
    opacity: .8;
  }

  &::after {
    z-index: -2;
  }

  &::before {
    z-index: -1;
  }

  &:hover {
    &::before {
      animation: glitch .24s cubic-bezier(.25, .46, .45, .94) .16s 1 both;
    }

    &::after {
      animation: glitch .24s cubic-bezier(.25, .46, .45, .94) .16s reverse both 1;
    }
  }
}