.PortfolioProject {
  position: relative;
  inline-size: 22rem;
  block-size: 14.5rem;
  overflow: hidden;
  border-radius: .5rem;
  box-shadow: $box-shadow;
  transition: .3s;

  .thumbnail {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    block-size: 100%;
    transition: all 0.3s ease-in-out;

    img {
      inline-size: 100%;
      block-size: $card-img-block-size;
      object-fit: cover;
    }

    .project__header {
      display: flex;
      justify-content: space-around;
      align-items: center;
      block-size: 3.5rem;

      .icons {
        display: flex;
        justify-content: space-around;
        gap: 1rem;
      }
    }
  }

  .hovered-infos {
    position: absolute;
    inset-block-start: 0;
    inset-inline-start: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: .8rem;
    inline-size: 100%;
    block-size: $card-img-block-size;
    padding-inline: 1.5rem;
    padding-block: 1rem;
    background: rgba($eerie-black, 0.94);
    animation: flip-in-hor-top 0.3s cubic-bezier(0.550, 0.055, 0.675, 0.190) forwards;
    font-size: .8rem;

    .source-code {
      display: flex;
      align-items: center;
      justify-content: space-around;
      inline-size: 100%;
    }
  }

  .specs {
    display: flex;
    justify-content: space-around;
    inline-size: 100%;
    flex: 1;

    h3 {
      text-align: center;
      margin: 0;
    }

    .skills {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: .5rem;

    }

    .skills-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 0 1rem;
    }
  }

  ul {
    margin: 0;
    padding: 0;
  }

  p {
    text-align: justify;
  }

  &:hover {
    box-shadow: 0 0 1rem $honeydew;
  }
}