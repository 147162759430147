* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

ul,
li {
  list-style: none;
}

::-webkit-scrollbar {
  inline-size: 0;
}

button {
  outline: none;
  font-family: inherit;
}