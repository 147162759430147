// THEMES
$eerie-black: #1C1C1D;
$air-force-blue: #6494AA;
$honeydew: #DDF8E8;
$apricot: #FFCAB1;
$mountbatten-pink: #946E83;
$platinum: #F2F4F3;

// ICON COLORS
$javascript-color: #f7df1e;
$react-color: #00d8ff;
$css-color: #1572B6;
$sass-color: #CC6699;

// TYPOGRAPHY
$text-shadow: 2px 1px 4px $honeydew;
$box-shadow: 0 0 .25rem $honeydew;
$glowing-shadow: 0 0 .75rem $air-force-blue;

// SIZES
$cursor-size: .5rem;
$nav-size: 20vi;
$card-img-block-size: 11rem;
$content-inline-size: 1200px;
$height: 1rem;