.portfolio {
  position: relative;
  padding-block-start: 6rem;

  .filters {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
    margin-block-start: 1rem;
  }

  input {
    display: none;
  }

  h1 {
    margin-block-end: 2rem;
  }

  .projects {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;
    padding-block: 2rem;
  }
}