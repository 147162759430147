@keyframes glitch {
  0% {
    transform: translate(0);
  }

  20% {
    transform: translate(-15px, 5px);
  }

  40% {
    transform: translate(-15px, -5px);
  }

  60% {
    transform: translate(15px, 5px);
  }

  80% {
    transform: translate(20px, -5px);
  }

  100% {
    transform: translate(0);
  }
}


@keyframes popup {
  to {
    opacity: 1;
  }
}


@keyframes flip-in-hor-top {
  from {
    transform: rotateX(-80deg);
    opacity: 0;
  }

  to {
    transform: rotateX(0);
    opacity: 1;
  }
}


@keyframes grow-shadow {
  0% {
    filter: none;
    transform: scale(1);
  }

  50% {
    filter: drop-shadow($box-shadow);
    transform: scale(1.1);
  }

  100% {
    filter: none;
    transform: scale(1);
  }
}


@keyframes animateCursor {
  0% {
    background-color: $apricot;
    transform: scale(1) translate(-50%, -50%);
    box-shadow: $box-shadow;

  }

  50% {
    background-color: $honeydew;
    transform: scale(2) translate(-50%, -50%);
    box-shadow: $glowing-shadow;

  }

  100% {
    background-color: $apricot;
    transform: scale(1) translate(-50%, -50%);
    box-shadow: $box-shadow;

  }
}

@keyframes jump {
  0% {
    transform: rotateY(0deg) translateY(0);
  }

  50% {
    transform: rotateY(90deg) translateY(-.5rem);
  }

  100% {
    transform: rotateY(180deg) translateY(0);
  }
}